<template>
  <div :class="containerClass">
    <b-row>
      <b-col cols="12">
        <div class="settings-title mb-3 mt-3">
          {{ $t('components.form.subscriptions.new.subscription-validity') }}
        </div>
        <b-row class="mt-1">
          <b-col cols="9">
            <b-row>
              <b-col class="mt-4" cols="6">
                <vs-radio
                  v-model="selectionDefined"
                  :vs-value="false"
                  :disabled="isDisabled"
                  color="#0B2772"
                  @change.stop="activate.duration = false"
                >
                  <label class="ml-2 black-col-title mt-2">
                    {{ $t('components.form.subscriptions.new.define-duration.label') }}
                  </label>
                </vs-radio>
              </b-col>
              <b-col cols="3">
                <b-row>
                  <b-col cols="12">
                    <label class="col-form-label-sm field-name-class">
                      {{ $t('components.form.subscriptions.new.define-duration.duration') }}
                    </label>
                  </b-col>
                  <b-col>
                    <d-text-field
                      v-model="subscriptionPlan.validityDurationCount"
                      :disabled="isDisabled ? true : activate.duration"
                      :min="0"
                      :rules="isDisabled ? undefined : !activate.duration ? validation.validityDurationCount : null"
                      place-holder="general.actions.undefined"
                      class-name="background-light-blue-inputs"
                      size="sm"
                      type="number"
                      error-text="validation.sm-required"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="3">
                <b-row>
                  <b-col cols="12">
                    <label class="col-form-label-sm field-name-class">
                      {{ $t('components.form.subscriptions.new.define-duration.interval') }}
                    </label>
                  </b-col>
                  <b-col cols="12">
                    <select
                      v-model="subscriptionPlan.validityPeriodicityType"
                      :disabled="isDisabled ? true : activate.duration"
                      size="sm"
                      class="form-control background-light-blue-inputs"
                      @change="validityPeriodicityTypeRequired = true"
                    >
                      <option disabled :value="null"> {{ $t('general.actions.choose') }}</option>
                      <option value="day">{{ $t('general.actions.day') }}</option>
                      <option value="week">{{ $t('general.actions.week.label') }}</option>
                      <option value="month">{{ $t('general.actions.months') }}</option>
                      <option value="year">{{ $t('general.actions.year') }}</option>
                    </select>
                    <b-form-invalid-feedback
                      :state="validityPeriodicityTypeRequired"
                    >
                      {{ $t('validation.required') }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-if="subscriptionPlan.validityPeriodicityType === 'month'">
              <b-col class="mt-4" cols="9">
                <label class="black-col-title mt-3">
                  {{ $t('components.form.subscriptions.new.commitment-duration-count.label') }}
                </label>
              </b-col>
              <b-col cols="3">
                <b-row>
                  <b-col cols="12">
                    <label class="field-name-class mt-2">
                      {{ $t('components.form.subscriptions.new.commitment-duration-count.duration') }}
                    </label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <d-text-field
                      v-model="subscriptionPlan.commitmentDurationCount"
                      :disabled="isDisabled ? true : activate.duration"
                      :rules="isDisabled ? undefined : !activate.duration ? validation.commitmentDurationCount : null"
                      :class-name="'background-light-blue-inputs'"
                      place-holder="general.actions.undefined"
                      error-text="validation.sm-required"
                      size="sm"
                      min="0"
                      type="number"
                      @change="showMonth"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="description-gray" cols="3">
            {{ $t('components.form.subscriptions.new.descriptions.subscription-duration') }}
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="9">
            <b-row>
              <b-col cols="9" class="pr-0 mt-2 black-col-title">
                {{ $t('components.form.subscriptions.new.descriptions.subscription-renewal-description') }}
              </b-col>
              <b-col cols="3">
                <select
                  v-model="subscriptionPlan.automaticRenewal"
                  :disabled="isDisabled ? true : activate.duration || subscriptionPlan.validityPeriodicityType !== 'month'"
                  size="sm"
                  class="form-control background-light-blue-inputs"
                >
                  <option :value="true"> {{ $t('general.actions.yes') }}</option>
                  <option :value="false"> {{ $t('general.actions.no') }}</option>
                </select>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="description-gray" cols="3">
            {{ $t('components.form.subscriptions.new.descriptions.subscription-renewal') }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div class="border-bottom-grey mt-2 mb-2"/>

    <b-row class="mt-1">
      <b-col cols="9">
        <b-row>
          <b-col class="mt-4" cols="6">
            <vs-radio
              v-model="selectionDefined"
              :vs-value="true"
              :disabled="isDisabled"
              color="#0B2772"
              @change.stop="activate.duration = true;validityPeriodicityTypeRequired = true"
            >
              <label class="black-col-title mt-2">
                {{ $t('components.form.subscriptions.new.define-period.label') }}
              </label>
            </vs-radio>
          </b-col>
          <b-col cols="3">
            <b-row>
              <b-col class="pl-1" cols="12">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.define-period.startAt') }}
                </label>
              </b-col>
              <b-col class="pl-0" cols="12">
                <d-vs-dropdown-form
                  v-if="selectionDefined"
                  :hide="hide"
                >
                  <template v-slot:dropdownbtn>
                    <d-text-field
                      :value="getSubscriptionPlanDate(subscriptionPlan.fromDate)"
                      :disabled="isDisabled ? true : !selectionDefined"
                      :rules="isDisabled ? undefined : validation.fromDate"
                      size="sm"
                      error-text="validation.required"
                      place-holder="general.actions.undefined"
                      class-name="background-light-blue-inputs"
                    />
                  </template>
                  <template v-slot:dropdownbody>
                    <date-picker
                      v-model="subscriptionPlan.fromDate"
                      :rules="isDisabled ? undefined : validation.toDate"
                      :inline="true"
                      :disabled-date="(date) => date < new Date() "
                      :lang="lang"
                      class="custom-datepicker"
                    />
                  </template>
                </d-vs-dropdown-form>
                <d-text-field
                  v-else
                  :disabled="true"
                  :class-name="'background-light-blue-inputs'"
                  :place-holder="'general.actions.undefined'"
                  size="sm"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="3">
            <b-row>
              <b-col class="pl-1" cols="12">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.define-period.endAt') }}
                </label>
              </b-col>
              <b-col class="pl-0" cols="12">
                <d-vs-dropdown-form
                  v-if="selectionDefined && subscriptionPlan.fromDate !== null"
                  :hide="hide"
                >
                  <template v-slot:dropdownbtn>
                    <d-text-field
                      :value="getSubscriptionPlanDate(subscriptionPlan.toDate)"
                      :disabled="isDisabled ? true : !selectionDefined"
                      :rules="isDisabled ? undefined : validation.toDate"
                      size="sm"
                      error-text="validation.required"
                      class-name="background-light-blue-inputs"
                      place-holder="general.actions.undefined"
                    />
                  </template>
                  <template v-slot:dropdownbody>
                    <date-picker
                      v-model="subscriptionPlan.toDate"
                      :inline="true"
                      :lang="lang"
                      :disabled-date="(date) => date <= subscriptionPlan.fromDate"
                      class="custom-datepicker"
                    />
                  </template>
                </d-vs-dropdown-form>
                <d-text-field
                  v-else
                  size="sm"
                  :disabled="true"
                  class-name="background-light-blue-inputs"
                  place-holder="general.actions.undefined"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="description-gray" cols="3">
        {{ $t('components.form.subscriptions.new.descriptions.subscription-duration') }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: {
    validator: {
      type: Boolean,
      default: true,
    },
    violations: {
      type: Array,
      default: () => [],
    },
    containerClass: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    subscriptionPlan: {
      type: Object,
      default: () => {
      },
    },
    validation: {
      type: Object,
      default: () => require('@validation/entities/doinsport/SubscriptionPlan.json'),
    }
  },
  data: () => ({
    hide: false,
    validityPeriodicityTypeRequired: true,
    activate: {
      duration: true,
      periodicity: true,
    },
    selectionDefined: null,
    handlerDeeped: false,
  }),
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    showMonth() {
      if (this.subscriptionPlan.commitmentDurationCount !== null) {
        return this.subscriptionPlan.commitmentPeriodicityType = 'month';
      } else {
        return this.subscriptionPlan.commitmentPeriodicityType = null;
      }
    }
  },
  watch: {
    validator: function () {
      if (!this.activate.duration) {
        this.validityPeriodicityTypeRequired = this.subscriptionPlan.validityPeriodicityType !== null;
        this.violations.push(this.validityPeriodicityTypeRequired);
      }
    },
    selectionDefined: function (selection) {
      if (this.handlerDeeped) {
        this.handlerDeeped = false;
      } else {
        this.subscriptionPlan.fromDate = null;
        this.subscriptionPlan.toDate = null;
        this.subscriptionPlan.validityDurationCount = null;
        this.subscriptionPlan.validityPeriodicityType = null;
        this.subscriptionPlan.commitmentPeriodicityType = null;
        this.subscriptionPlan.commitmentDurationCount = null;
      }
    },
    subscriptionPlan: {
      deep: true,
      handler() {
        this.initSelectionDefined();
      }
    }
  },
  methods: {
    initSelectionDefined() {
      if (this.subscriptionPlan.id) {
        this.selectionDefined = !(this.subscriptionPlan.fromDate === null && this.subscriptionPlan.toDate == null);
        if (this.subscriptionPlan.fromDate === null && this.subscriptionPlan.toDate === null) {
          this.activate.duration = false;
        }
        this.handlerDeeped = true;
      }
    },
    getSubscriptionPlanDate(date) {
      if (date) {
        return this.$moment(date).format('DD/MM/YYYY');
      } else {
        return null;
      }
    }
  },
  created() {
    this.initSelectionDefined();
  }
}
</script>
<style lang="scss" scoped>
.description-gray {
  text-align: left;
  font: oblique normal 15px Avenir;
  letter-spacing: 0px;
  color: #8a8a92;
}

/deep/ .con-vs-radio {
  justify-content: left;
}

/deep/ .has-calendar .form-control-feedback {
  text-align: left;
  line-height: 2.7rem;
}

/deep/ .mx-calendar-content .cell.active {
  color: #fff;
  background-color: #206D84;
  border-radius: 3px;
  padding-top: 0px;
}
</style>

