import { render, staticRenderFns } from "./ValidityInformations.vue?vue&type=template&id=5d55bb06&scoped=true&"
import script from "./ValidityInformations.vue?vue&type=script&lang=js&"
export * from "./ValidityInformations.vue?vue&type=script&lang=js&"
import style0 from "./ValidityInformations.vue?vue&type=style&index=0&id=5d55bb06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d55bb06",
  null
  
)

export default component.exports